import LocationModal from './LocationModal'
import LocationImport from './LocationImport'
import locationService from '../../../services/supplier/location'
import { Supplier_Location_Headers, AG_GridCustomizedTableHeaders_Key } from '../../../misc/tableHeaders'
export default {
    components: {
        LocationModal,
        LocationImport
    },
    data() {
        return {
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            gridApi: null,
            columnApi: null,
            total: 0,
            items: [],
            status: 'All',
            reportTitle: 'Location Report',
            search: {
                idn: null,
                locationId: null,
                locationName: null,
                address1: null,
                address2: null,
                state: null,
                city: null,
                zipCode: null,
                notisphereId: null,
                verified: null,
                status: null
            },
            locationModalKey: 0,
            locationImportKey: 0,
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
        }
    },
    beforeMount() {
        this.columnDefs = Supplier_Location_Headers
    },
    created() {
        this.rowModelType = 'serverSide'
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Supplier_Login.Location_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Supplier_Login?.Location_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onCellClicked(params) {
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'delete') {
                    this.deleteLocation(params.data)
                }
                if (params.event.target.dataset.action === 'edit') {
                    this.updateLocation(params.data)
                }
                return
            }else{
                this.updateLocation(params.data)
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Supplier_Login.Location_Filters = event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Supplier_Login?.Location_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
        },
        addMoreClick() {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderInsert()
            })
        },
        updateLocation(payload) {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerenderUpdate(payload.location_id)
            })
        },
        fetchData(params) {
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            const datasource = {
                getRows(params) {
                    that.search.idn = params.request?.filterModel?.idn?.filter
                    that.search.locationId = params.request?.filterModel?.location_id?.filter
                    that.search.locationName = params.request?.filterModel?.location_name?.filter
                    that.search.address1 = params.request?.filterModel?.address1?.filter
                    that.search.address2 = params.request?.filterModel?.address2?.filter
                    that.search.city = params.request?.filterModel?.city?.filter
                    that.search.state = params.request?.filterModel?.state?.filter
                    that.search.zipCode = params.request?.filterModel?.zip_code?.filter
                    that.search.notisphereId = params.request?.filterModel?.notisphere_id?.filter
                    that.search.last_change_at = params.request?.filterModel?.last_change_at?.dateFrom?.split(' ')[0]
                    that.search.status = params.request?.filterModel?.status?.filter
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    locationService.findLocation({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search
                    }).then(resp => {
                        if (!resp.error) {
                            that.total = resp.data.d.t
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return resp.data.d.c
                        }
                    }).then(response => {
                        params.successCallback(response)
                    }).catch(error => {
                        console.error(error)
                        params.failCallback()
                    })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)
        },
        downloadsupplierlocationReport() {
            locationService.findSupplierLocationReport()
        },
        exportsupplierlocationReport() {
            locationService.findSupplierLocationCSVReport({
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                sortField: null,
                sortDirection: null,
                status: this.status,
            })
        },
        deleteLocation(item, e) {
            this._showConfirm('You are about to delete this location, this cannot be undone.').then(confirm => {
                if (confirm) {
                    locationService.deleteLocation(item.location_id).then(resp => {
                        if (!resp.error) {
                            this.onCompleted('UPDATE')
                        }
                    })
                }
            })
        // e.stopPropagation()
        },
        importFile() {
            this.locationImportKey += 1
            this.$nextTick(() => {
                this.$refs.locationImport.forceRerenderInsert()
            })
        },
        downloadRecallSummaryReport(ext) {
            locationService.downloadSupplierLocationReportReport(ext)
        },
    }
}